<template>
  <div>
    <header class="h-60 w-full bg-blue">
      <div class="h-full w-full flex justify-center items-center">
        <img src="./assets/cresco-webb.png" alt="Logo" class="w-40 mt-16" />
      </div>
    </header>
    <main>
      <div class="w-full py-8 md:py-12">
        <h1 class="text-green text-4xl text-center">
          Utvecklings- och Nätverksprogram <br />
          för chefer i ledande befattning
        </h1>
      </div>
      <!-- text / video container -->
      <div class="xl:flex xl:pb-32">
        <!-- Left wrapper /text -->
        <div class="w-full xl:w-6/12 px-4 md:px-8 flex xl:justify-end">
          <div class="xl:w-7/12 xl:mr-16 text-semiBlack">
            <h3 class="text-semiBlack text-xl font-semibold">Vad är Cresco?</h3>
            <p>
              Cresco är ett Utvecklings- och nätverksprogram för dig i
              chefsposition. Genom möten med andra ledare och genom att arbeta
              med egna utmaningar ger Cresco dig möjligheter att vidga
              perspektiven, ge dig andra infallsvinklar och utvecklas i din roll
              som ledare. Cresco är en utvecklings- och nätverksprogram där du
              får en coach som följer dig under programmet.
            </p>
            <p class="py-4">
              Programmet består av både fysiska och virtuella träffar där stor
              vikt läggs på att skapa ett lärande gemensamt genom reflektion
              kring aktuella ämnen och teman. Som deltagare förväntas du att
              delta aktivt och bidra med din erfarenhet och nyfikenhet. Vi tror
              på kontinuerlig utveckling där tillitsfullt nätverkande är
              grogrund för ett bra ledarskap och att kunna hantera med- och
              motgångar.
            </p>
            <div class="pb-4">
              <h4 class="font-semibold text-md">Varför Cresco?</h4>
              <ul class="list-disc pl-5">
                <li>
                  Få nya professionella vänner kolleger genom tillitsfullt
                  nätverkande
                </li>
                <li>
                  Utmana din kompetens inom intressanta och aktuella ämnen.
                </li>
                <li>
                  Få olika perspektiv på ledarskapet och bli en bättre ledare.
                </li>
                <li>Investera i din egen utveckling.</li>
              </ul>
            </div>
            <div class="pb-8">
              <h4 class="font-semibold text-md">Upplägg</h4>
              <p>
                Som deltagare kommer du att delta i totalt 12 olika aktiviteter:
              </p>
              <ul class="list-disc pl-5">
                <li>4 fysiska träffar (totalt 5 dagar)</li>
                <li>4 virtuella träffar</li>
                <li>4 virtuella föreläsningar</li>
                <li>Individuell coachande handledning</li>
              </ul>
            </div>
            <p>
              För fullständig information ladda ner produktblad (pdf).
              <a
                :href="pdfLink"
                download="Produktblad-Cresco"
                class="font-semibold underline"
                >Klicka här</a
              >
            </p>
          </div>
        </div>
        <!-- Right wrapper / video -->
        <div class="xl:w-6/12 flex justify-center xl:block">
          <div class="clip bg-lightGray mx-4 my-16 xl:mx-0 xl:my-0">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/EbbFDWgu63M"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </main>
    <!-- Footer -->
    <footer class="w-full bg-blue">
      <div class="w-full pt-28 pb-20">
        <h2 class="text-yellow text-4xl text-center">
          Kontakta oss för mer information
        </h2>
      </div>
      <div class="md:flex justify-center pb-40 text-white">
        <address class="text-center mx-8 mb-12 md:mb-0">
          <h5 class="mb-4 font-bold">Anna-Karin L Boman</h5>
          <div class="mb-4">
            <a href="tel:+46706060613"
              ><p class="text-lightGray">M. +46 (0)70 60 60 613</p></a
            >
            <a href="mailto:webmaster@example.com"
              ><p class="text-lightGray">anna-karin.l.boman@annpocon.se</p></a
            >
          </div>
          <a href="https://www.annpocon.se" target="_blank"
            ><p class="font-bold text-lg">www.annpocon.se</p></a
          >
        </address>
        <address class="text-center mx-8">
          <h5 class="mb-4 font-bold">Martina Skansjö</h5>
          <div class="mb-4 text-lightGray">
            <a href="tel:+46708477118"
              ><p class="text-lightGray">M. +46 (0)708 47 71 18</p></a
            >
            <a href="mailto:webmaster@example.com"
              ><p class="text-lb">martina@skansjoconsulting.se</p></a
            >
          </div>

          <a href="https://www.skansjoconsulting.se" target="_blank"
            ><p class="font-bold text-lg">www.skansjoconsulting.se</p></a
          >
        </address>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      pdfLink: "../../files/Produktblad-Cresco-new.pdf",
    };
  },
};
</script>

<style>
.clip {
  width: 35rem;
  height: 35rem;
}

@media only screen and (max-width: 600px) {
  .clip {
    width: 21rem;
    height: 21rem;
  }
}
</style>
